<!-- 车商信息 -->
<template>
  <div class="CarDealer">
    <div class="mainTxt">车商信息</div>
    <div class="list" v-for="item in channelList" :key="item.shopCode">
      <div class="left">
        <div class="top">{{ item.enterpriseName }}</div>
        <div class="bottom">{{ item.shopAddress }}</div>
      </div>
      <div class="right" @click="callPhone(item.contacterPhone)">
        <img src="@/assets/images/phone.png" alt="" />
        <p>电话</p>
      </div>
    </div>
    <div
      v-show="source && source.length > 5 && channelList.length <= 5"
      class="c_moreInfo"
      @click="showMore"
    >
      查看更多
    </div>
  </div>
</template>

<script>
export default {
  name: "CarDealer",
  props: {
    source: { type: Array }
  },
  data() {
    return {
      channelList: []
    };
  },
  watch: {
    source: {
      handler() {
        if (this.source && this.source.length) {
          this.channelList = JSON.parse(JSON.stringify(this.source));
          if (this.channelList.length > 5) {
            this.channelList.length = 5;
          }
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    callPhone(phone) {
      window.location.href = `tel:${phone}`;
    },
    showMore() {
      this.channelList = JSON.parse(JSON.stringify(this.source));
    }
  }
};
</script>
<style lang="scss" scoped>
.CarDealer {
  .list {
    height: 0.66rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #f1f2f6;
    .left {
      .top {
        font-size: 0.15rem;
        font-weight: bold;
        line-height: 0.21rem;
      }
      .bottom {
        font-size: 0.12rem;
        color: #8d8d91;
        line-height: 0.17rem;
        margin-top: 0.04rem;
      }
    }
    .right {
      width: 0.24rem;
      flex-shrink: 0;
      text-align: center;
      img {
        width: 0.13rem;
        height: 0.16rem;
      }
      p {
        font-size: 0.12rem;
        color: #3a3d46;
        margin-top: 0.08rem;
      }
    }
  }
  .c_moreInfo {
    background: #f8f8f8;
    box-shadow: 0 2px 6px 0 rgba(105, 105, 152, 0.03);
    color: #6e727a;
    margin-top: 0.2rem;
  }
}
</style>
