<!-- 分期购车详情 -->
<template>
  <div class="CarDetail">
    <div class="imgBg">
      <img class="bigImg" :src="carData.whiteImg" @click="showBigImg" />
    </div>
    <div class="mainContent">
      <!-- 基础信息 -->
      <CarInfo :source="carData" />
      <!-- 分期方案 -->
      <Plans :source="planInfo" from="1" @currentChange="currentChange" />
      <!-- 车商信息 -->
      <CarDealer v-show="dealerInfo.length" :source="dealerInfo" />
      <div class="mainTxt">购车流程</div>
      <p class="buyCarText">① 线上咨询或到店咨询(4S经销商)</p>
      <p class="buyCarText">② 与金融顾问沟通产品方案</p>
      <p class="buyCarText">③ 提交购车申请，最快当天提车</p>
      <p class="buyCarText">④ 按月支付租金</p>
      <div class="c_redBtn" @click="nextPage">购车咨询</div>
    </div>
  </div>
</template>

<script>
import CarInfo from "./components/CarInfo";
import Plans from "./components/Plans";
import CarDealer from "./components/CarDealer";
import { getCarModelDetail, getSerialHot } from "@/api/api_carInstallment";
import { mapState } from "vuex";
export default {
  name: "CarDetail",
  data () {
    return {
      hotNum: 0,
      carData: {},
      planInfo: [],
      dealerInfo: [],
      serialId: this.$route.query.serialId,
      styleId: this.$route.query.styleId,
      ciyInfo: this.$store.state.position // 获取城市码值
    };
  },
  computed: {
    ...mapState(["routerMsg", "loginInfo"])
  },
  created () {
    this.getHot();
    this.getInfo();
  },
  methods: {
    currentChange (index) {
      // 存储信息，用于购车咨询页面
      this.$store.commit("setCarInfo", {
        ...this.carData,
        planInfo: [this.planInfo[index]]
      });
    },
    showBigImg () {
      this.$ImagePreview([this.carData.whiteImg]);
    },
    // 获取热度
    getHot () {
      getSerialHot({
        cityId: this.ciyInfo.cityId,
        serialId: this.serialId
      }).then(res => {
        if (res.code === 0) {
          const hotNum = res.data.info;
          // 设置 Header 显示文案
          this.routerMsg.title = `${hotNum}人感兴趣`;
          this.$store.commit("setRouterMsg", this.routerMsg);
        }
      });
    },
    // 获取详情页信息
    getInfo () {
      getCarModelDetail({
        cityId: this.ciyInfo.cityId,
        styleId: this.styleId, // 车型id，选择车型返回必传
        serialId: this.serialId // 车系id
      }).then(res => {
        if (res.code === 0) {
          const result = res.data.info;
          this.carData = result.CarModelDetail[0];
          this.planInfo = result.productList;
          this.dealerInfo = result.channelList;
          const carName = `${this.carData.masterBrandName} ${this.carData.serialName} ${this.carData.year} ${this.carData.styleName}`;
          this.carData.carName = carName;
          // 存储信息，用于购车咨询页面
          this.$store.commit("setCarInfo", {
            ...this.carData,
            planInfo: [this.planInfo[0]]
          });
        }
      });
    },
    nextPage () {
      if (this.loginInfo.userId) {
        this.$router.push({ name: "BuyAdvisory" });
      } else {
        this.$router.push({
          name: "Login",
          params: { name: "BUY_ADVISORY" }
        });
      }
    }
  },
  components: { CarInfo, Plans, CarDealer }
};
</script>
<style lang="scss">
.CarDetail {
  background: #fff;
  .imgBg {
    background: #f3f4f5;
    text-align: center;
    .bigImg {
      height: 2.2rem;
    }
  }
  .mainTxt {
    font-size: 0.2rem;
    font-weight: bold;
    margin: 0.3rem 0 0.12rem;
  }
  .mainContent {
    position: relative;
    z-index: 2;
    top: -0.2rem;
    background: #fff;
    border-radius: 0.2rem 0.2rem 0 0;
    padding: 0.2rem 0.18rem 0.6rem;
    .buyCarText {
      color: #6e727a;
      line-height: 0.2rem;
      margin-bottom: 0.12rem;
    }
    .c_redBtn {
      position: fixed;
      bottom: 0.06rem;
      left: 0.18rem;
      right: 0.18rem;
    }
  }
}
</style>
