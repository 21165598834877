<!-- 详情-车信息 -->
<template>
  <div class="CarInfo">
    <div class="bigTitle" @click="nextPage">
      <p class="van-multi-ellipsis--l2">{{ source.carName }}</p>
      <img src="@/assets/images/right_arrow.png" alt="" />
    </div>
    <p class="referPrice">
      厂商指导价 <span>{{ source.styleReferPrice }}</span> 万
    </p>
    <div class="info">
      <div class="item">
        <p class="title">发动机</p>
        <p class="value van-ellipsis">{{ source.groupName }}</p>
      </div>
      <div class="item">
        <p class="title">变速箱</p>
        <p class="value van-ellipsis">{{ source.tranAndGearNum }}</p>
      </div>
      <div class="item">
        <p class="title">长*宽*高(mm）</p>
        <p class="value van-ellipsis">{{ source.measure }}</p>
      </div>
      <div class="item">
        <p class="title">综合油耗(L/100km)</p>
        <p class="value van-ellipsis">{{ source.fuelConsumption }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CarInfo",
  props: {
    source: { type: Object }
  },
  methods: {
    nextPage() {
      this.$router.push({
        name: "CarSelect",
        query: { serialId: this.source.serialId, cityId: this.source.cityId }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.CarInfo {
  .bigTitle {
    display: flex;
    align-items: center;
    p {
      flex: 1;
      font-size: 0.24rem;
      line-height: 0.33rem;
      font-weight: bold;
    }
    img {
      width: 0.07rem;
      margin-left: 0.12rem;
    }
  }
  .referPrice {
    font-size: 0.16rem;
    margin-top: 0.12rem;
    line-height: 0.22rem;
    span {
      color: $mainRed;
    }
  }
  .info {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .item {
      margin-top: 0.16rem;
      &:nth-child(1),
      &:nth-child(3) {
        width: 60%;
      }
      &:nth-child(2),
      &:nth-child(4) {
        width: 35%;
      }
      .title {
        font-size: 0.12rem;
        color: #8d8d91;
        line-height: 0.17rem;
      }
      .value {
        font-size: 0.14rem;
        color: #3a3d46;
        line-height: 0.2rem;
        margin-top: 0.08rem;
      }
    }
  }
}
</style>
