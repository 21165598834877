<!-- 分期方案 -->
<template>
  <div class="Plans">
    <p class="mainTxt" v-if="from === '1'">分期方案</p>
    <van-swipe class="my-swipe" indicator-color="#FF4848" @change="onChange">
      <van-swipe-item v-for="item in source" :key="item.planId">
        <p class="name">{{ item.planName }}</p>
        <div class="mainInfo">
          <div class="item">
            <p class="top">{{ item.downPaymentRatio }}</p>
            <p class="bottom">首付比例</p>
          </div>
          <div class="item">
            <p class="top">{{ item.productRateDesc }}</p>
            <p class="bottom">利率（%）</p>
          </div>
          <div class="item">
            <p class="top">{{ item.productLoanPeriodDesc }}</p>
            <p class="bottom">期数（月）</p>
          </div>
          <div class="item">
            <p class="top">{{ item.financingAmount }}</p>
            <p class="bottom">融资额度（万元）</p>
          </div>
        </div>
        <p class="desc">{{ item.description }}</p>
      </van-swipe-item>
    </van-swipe>
  </div>
</template>

<script>
export default {
  name: "Plans",
  props: {
    source: { type: Array },
    from: { type: String }
  },
  methods: {
    onChange(index) {
      this.$emit("currentChange", index);
    }
  }
};
</script>
<style lang="scss" scoped>
.Plans {
  .my-swipe {
    box-shadow: 0 1px 6px 1px #e4ecf3;
    border-radius: 0.04rem;
    .van-swipe-item {
      padding: 0.18rem 0.2rem;
    }
    .name {
      font-size: 0.15rem;
      font-weight: bold;
      line-height: 0.21rem;
      margin-bottom: 0.21rem;
    }
    .mainInfo {
      .item {
        width: 50%;
        display: inline-block;
        text-align: center;
        margin-bottom: 0.16rem;
        .top {
          font-size: 0.18rem;
          line-height: 0.25rem;
          color: $mainRed;
        }
        .bottom {
          color: #333333;
          line-height: 0.2rem;
          margin-top: 0.07rem;
        }
      }
    }
    .desc {
      font-size: 0.12rem;
      color: #8d8d91;
      line-height: 0.18rem;
      margin-bottom: 0.08rem;
    }
  }
}
</style>

<style lang="scss">
.my-swipe .van-swipe__indicator {
  background-color: #d8d8d8;
  opacity: 1;
}
</style>
